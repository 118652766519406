import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';

import BulkLocationTransfer from '@/components/locationTransfer/BulkLocationTransfer.vue';
import HomePage from '@/pages/Home.vue';
// import BulkListView from '@/components/locationTransfer/BulkListView.vue';
import LocationDashboardReport from '@/components/LocationDashboardReport.vue';
import TransferList from '@/components/locationTransfer/TransferList.vue';
import ScheduleList from '@/components/schedule/ScheduleList.vue';

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/sub-accounts/dashboard/:locationId',
    component: LocationDashboardReport,
  },
  {
    path: '/sub-accounts/schedules',
    component: ScheduleList,
  },
  {
    path: '/sub-accounts/transfer-locations',
    component: TransferList,
  },
  {
    path: '/sub-accounts/transfer-locations/initiate-bulk-transfer',
    component: BulkLocationTransfer,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export { router };
