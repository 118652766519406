<template>
  <div>
    <div class="flex gap-2">
      <div class="flex gap-2">
        <div class="my-auto text-lg font-semibold text-gray-900">
          {{
            $t('subAccRequest', {
              tabName: tabName === 'sent' ? $t('release') : $t('receive'),
            })
          }}
        </div>
        <div
          v-if="totalRequests > 0"
          class="my-auto rounded-2xl bg-gray-100 px-2 py-0.5 align-middle text-sm font-medium"
        >
          {{ $t('requests', { count: totalRequests }) }}
        </div>
      </div>

      <!-- <div class="ml-auto w-80">
        <UIInput
          :id="'search'"
          v-model="searchInput"
          :placeholder="$t('searchSubAccName')"
          @update:model-value="updateSearchInput"
        >
          <template #prefix
            ><SearchLgIcon class="h-5 w-5"></SearchLgIcon
          ></template>
        </UIInput>
      </div> -->
    </div>

    <UIContentWrap id="transfer-table">
      <UITable
        id="transfer-request-table"
        v-model:search-text="table.searchText"
        class="mt-16 flex content-center items-center justify-center text-center"
        :columns="table.tableHeading"
        :data="table.computedData"
        :tabs="tableTabs"
        :default-tab="defaultTab"
        :striped="true"
        :enable-filter="false"
        :loading="table.isLoading"
        :page-count="table.totalPages"
        :page="table.currentPage"
        :search-input-placeholder="
          bulk ? $t('searchSubAccountsId') : $t('searchSubAccounts')
        "
        :enable-text-search="true"
        @update:page="pageChange"
        @update:default-tab="updateDefaultTab"
        @update:search-text="searchInputChange"
      >
      </UITable>
      <br />
    </UIContentWrap>
  </div>

  <UIModal
    v-model:show="transferTableDetails.actionModalDetails.value.show"
    :width="400"
  >
    <template #header>
      <UIModalHeader
        id="modal-header"
        :type="transferTableDetails.actionModalDetails.value.modalType"
        :icon="transferTableDetails.actionModalDetails.value.icon"
        @close="
          transferTableDetails.actionModalDetails.value =
            transferTableDetails.defaultActionValues
        "
      >
      </UIModalHeader>
    </template>
    <div class="text-lg font-semibold">
      {{ transferTableDetails.actionModalDetails.value.heading }}
    </div>
    <div class="font-regular pt-2 text-sm text-gray-600">
      {{ transferTableDetails.actionModalDetails.value.description }}
    </div>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="
          transferTableDetails.actionModalDetails.value.positiveButtonText
        "
        :type="transferTableDetails.actionModalDetails.value.positiveButtonType"
        :negative-text="
          transferTableDetails.actionModalDetails.value.negativeButtonText
        "
        :loading="buttonDisable"
        @negative-click="
          transferTableDetails.actionModalDetails.value =
            transferTableDetails.defaultActionValues
        "
        @positive-click="transferRequestReply"
      >
      </UIModalFooter>
    </template>
  </UIModal>

  <UIModal v-model:show="errorDetails.show" :width="400">
    <template #header>
      <UIModalHeader
        id="error-modal-header"
        type="error"
        :title="errorDetails.title"
        :icon="SlashCircle01Icon"
        @close="errorDetails = defaultErrorValues"
      ></UIModalHeader>
    </template>
    <div>
      <UIAlert id="warning" type="warning" :closable="false">
        <template #title>{{ errorDetails.description }}</template>
      </UIAlert>
    </div>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="errorDetails.buttonText"
        @positive-click="errorDetails = defaultErrorValues"
      >
      </UIModalFooter>
    </template>
  </UIModal>

  <UIModal v-model:show="showUpsellModal" :width="400">
    <template #header>
      <UIModalHeader
        id="error-modal-header"
        type="warning"
        :title="$t('subAccLimit')"
        :description="$t('subAccPlanLimit')"
        :icon="Upload04Icon"
        @close="showUpsellModal = false"
      ></UIModalHeader>
    </template>
    <div
      class="mt-4 flex !h-32 gap-2 rounded-lg border border-solid border-gray-200"
    >
      <img
        src="https://storage.googleapis.com/preview-production-assets/saas/img/upgrade-upsell.svg"
      />
      <div class="!my-auto">
        <div class="text-base font-semibold text-gray-800">
          {{ $t('upgradeTime') }}
        </div>
        <div class="font-regular text-sm text-gray-600">
          {{ $t('upgradeHigher') }}
        </div>
      </div>
    </div>
    <template #footer>
      <UIButton
        id="browse-plans"
        type="primary"
        class="mt-3 w-full"
        @click.prevent="navigateToBilling"
        >{{ $t('browse') }}</UIButton
      >
    </template>
  </UIModal>

  <UIModal v-model:show="showSuccessModal" :width="400">
    <template #header>
      <UIModalHeader
        id="error-modal-header"
        type="success"
        :icon="CheckCircleIcon"
        @close="showSuccessModal = false"
      ></UIModalHeader>
    </template>
    <div class="text-lg font-semibold">{{ $t('subAccountTransferred') }}</div>
    <div class="font-regular pt-2 text-sm text-gray-600">
      {{ $t('subAccountTransferDescription') }}
    </div>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="$t('done')"
        :type="'primary'"
        :negative-text="
          transferTableDetails.actionModalDetails.value.negativeButtonText
        "
        @positive-click="showSuccessModal = false"
      />
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { router } from '@/router';
import { InternalToolsService } from '@/services/InternalToolsService';
import { useAppStore } from '@/store/app';
import {
  CheckCircleIcon,
  SlashCircle01Icon,
  Upload04Icon,
} from '@gohighlevel/ghl-icons/24/outline';
import {
  UIAlert,
  UIButton,
  UIContentWrap,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UITable,
} from '@gohighlevel/ghl-ui';
import { debounce } from 'lodash';
import { defineProps, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocationTransferTable } from '../../composables/useLocationTransferTable';
import { useTable } from '../../composables/useTable';
import { TransferReply, TransferRequestFetchParams } from './Transfer.const';
const { renderNameAndEmail, renderNameEmailAndLogo, renderDate, renderEject } =
  useTable();

const props = defineProps({
  tabName: { type: String, required: true },
  bulkOption: { type: Boolean, required: false },
  bulk: { type: Boolean, required: false },
});

const { t } = useI18n();
const appStore = useAppStore();
const buttonDisable = ref<boolean>(false);
const searchInput = ref<string>('');
const showSuccessModal = ref<boolean>(false);
const totalRequests = ref<number>(0);
const showUpsellModal = ref<boolean>(false);
const emit = defineEmits(['updateTab', 'viewDetails']);
const transferErrors = (code: string, extraDetails?: string) => {
  const errors = {
    PENDING_TRANSFER_REQUEST: {
      code: 'PENDING_TRANSFER_REQUEST',
      heading: t('pendingTransferTitle'),
      message: t('pendingTransferDescription'),
    },
    SUB_ACCOUNT_HIPAA_ENABLED: {
      code: 'SUB_ACCOUNT_HIPAA_ENABLED',
      heading: t('unableTransfer'),
      message: t('hippaErrorDescription'),
    },
    SUB_ACCOUNT_LIMIT_EXCEEDED: {
      code: 'SUB_ACCOUNT_LIMIT_EXCEEDED',
      heading: t('unableTransfer'),
      message: t('pendingTransferDescription'),
    },
    ACTIVE_SUBSCRIPTIONS: {
      code: 'ACTIVE_SUBSCRIPTIONS',
      heading: t('unableTransfer'),
      message: t('activeSubscriptionsAlert', { subscriptions: extraDetails }),
    },
    TRANSFER_REQUEST_ALREADY_COMPLETED: {
      code: 'TRANSFER_REQUEST_ALREADY_COMPLETED',
      heading: t('unableTransfer'),
      message: t('transferCompleted'),
    },
  };
  return errors[code];
};

const tableTabs = props?.bulkOption
  ? [
      {
        label: 'Single Request',
        key: 'single',
      },
      {
        label: 'Bulk Request',
        key: 'bulk',
      },
    ]
  : [];

const defaultTab = ref<string>(props.bulk ? 'bulk' : 'single');
const transferTableDetails = useLocationTransferTable(emit, {
  tabName: props.tabName,
  bulk: defaultTab.value === 'single' ? false : true,
});
const defaultErrorValues = {
  showModal: false,
  title: '',
  description: '',
  buttonText: t('goBack'),
};
const errorDetails = ref<any>(defaultErrorValues);

const table = ref<any>({
  isLoading: false,
  tableHeading: transferTableDetails.getSingleTableHeaders(),
  data: [],
  computedData: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  searchText: '',
});

onMounted(async () => {
  await fetchTransferRequestData();
});

function navigateToBilling() {
  //settings/billing
  const path = `/settings/billing`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then(parent => {
      parent.emit('route-change', {
        path,
      });
    });
  }
  router.push(path);
}

async function transferRequestReply() {
  try {
    buttonDisable.value = true;

    if (!appStore.user?.isAgencyOwner) {
      throw {
        errorCode: 'NOT_AUTHORIZED',
        message: t('unauthorizedTransfer'),
      };
    }

    //TRANSFER_REQUEST_ALREADY_COMPLETED

    if (!appStore?.companyId) {
      return setTimeout(async () => transferRequestReply(), 1000);
    }
    const payload = {
      companyId: appStore?.companyId,
      requestId: transferTableDetails.selectedRowDetails.value.id,
      reply: transferTableDetails.selectedRowDetails.value.action,
    };
    const ejectPayload = {
      companyId: appStore?.companyId,
      reply: transferTableDetails.selectedRowDetails.value.action,
    };

    if (transferTableDetails.actionModalDetails.value.type === 'eject') {
      await InternalToolsService.replyToEjectRequest(
        transferTableDetails.selectedRowDetails.value.id,
        ejectPayload
      )
    } else if (
      transferTableDetails.actionModalDetails.value.type === 'bulk-transfer'
    ) {
      await InternalToolsService.replyBulkTransferRequest(
        transferTableDetails.selectedRowDetails.value.id,
        {
          reply: transferTableDetails.selectedRowDetails.value.action,
          companyId: appStore?.companyId,
        }
      )
    } else {
      await InternalToolsService.replyToTransferRequest(payload);
    }

    if (
      transferTableDetails.selectedRowDetails.value.action ===
      TransferReply.ACCEPT
    ) {
      showSuccessModal.value = true;
    }
    await fetchTransferRequestData();
  } catch (error) {
    if (error?.response?.data?.errorCode) {
      const transferErrorDetails = transferErrors(
        error?.response?.data?.errorCode,
        error?.response?.data?.extraDetails
      );
      if (transferErrorDetails?.code === 'SUB_ACCOUNT_LIMIT_EXCEEDED') {
        showUpsellModal.value = true;
      } else if (transferErrorDetails) {
        errorDetails.value = {
          show: true,
          title: transferErrorDetails.heading,
          description: transferErrorDetails.message,
        };
      }
    }

    if (!errorDetails.value.show && !showUpsellModal.value) {
      let errorMessage;
      if (
        error?.response?.status === 401 ||
        error?.errorCode === 'NOT_AUTHORIZED'
      ) {
        errorMessage = t('unauthorizedTransfer');
      }
      errorDetails.value = {
        show: true,
        title:
          transferTableDetails.actionModalDetails.value.type === 'eject'
            ? t('unableEjectCancel')
            : t('unableTransfer'),
        description:
          transferTableDetails.actionModalDetails.value.type === 'eject'
            ? t('EjectErrorDetails')
            : errorMessage || t('transferErrorDetails'),
      };
    }
  } finally {
    transferTableDetails.actionModalDetails.value =
      transferTableDetails.defaultActionValues;
    buttonDisable.value = false;
  }
}

async function fetchTransferRequestData() {
  try {
    table.value.isLoading = true;
    if (props.tabName === 'eject') {
      return fetchEjectRequestData();
    }

    if (!appStore.companyId) {
      return setTimeout(async () => fetchTransferRequestData(), 1000);
    }
    const companyId = appStore.companyId;
    const param: TransferRequestFetchParams = props.bulk
      ? {}
      : { companyId: companyId }
    if (props.tabName === 'received') {
      param.type = `received`;
    }
    if (props.tabName === 'sent') {
      param.type = `sent`;
    }

    if (table.value.searchText) {
      param.subAccountName = table.value.searchText;
    }

    param.limit = table.value.pageSize;
    param.skip = (table.value.currentPage - 1) * table.value.pageSize;
    //props.bulk
    // ? await InternalToolsService.fetchBulkTransferRequests(param)
    //       :
    const { data: transferRequestData } = props.bulk
      ? await InternalToolsService.fetchBulkTransferRequestsForCompany(
          companyId,
          param
        )
      : await InternalToolsService.fetchLocationTransferRequests(param)

    totalRequests.value = transferRequestData.count;
    table.value.data = transferRequestData.transferData;
    table.value.computedData = transferRequestData.transferData;
    table.value.totalPages = Math.ceil(
      transferRequestData.count / table.value.pageSize
    );
  } catch (error) {
    table.value.data = [];
    table.value.computedData = [];
  } finally {
    table.value.isLoading = false;
  }
}

async function fetchEjectRequestData() {
  try {
    table.value.isLoading = true;
    if (!appStore.companyId) {
      return setTimeout(async () => fetchEjectRequestData(), 1000);
    }
    const queryString = `companyId=${appStore.companyId}`;

    const { data: transferRequestData } =
      await InternalToolsService.fetchEjectRequests(queryString);
    totalRequests.value = transferRequestData.length;
    table.value.data = transferRequestData;
    table.value.computedData = transferRequestData;
  } catch (error) {
    table.value.data = [];
    table.value.computedData = [];
  } finally {
    table.value.isLoading = false;
  }
}

const debounceFetch = debounce(() => {
  fetchTransferRequestData();
}, 500);

function searchInputChange(value) {
  table.value.searchText = value;
  debounceFetch();
}

function pageChange(page: number) {
  table.value.currentPage = page;
  fetchTransferRequestData();
}

function updateDefaultTab(tab: string) {
  emit('updateTab', tab);
}
</script>

<style scoped></style>
