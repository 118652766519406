import { requests } from '.';
export const LocationService = {
  getAll: (
    companyId: string,
    query?: string,
    limit = 50,
    skip = 0,
    order = 'desc',
    projection = ''
  ) =>
    requests().get('/locations/search', {
      params: {
        deleted: false,
        companyId,
        query,
        limit,
        skip,
        order,
        projection,
      },
    }),
  getById: (companyId: string, locationId: string) =>
    requests().get(`/locations/${locationId}`, {
      params: {
        companyId,
      },
    }),
  getAllTasks: (
    locationId: string,
    body: { count: boolean; overdue: boolean; limit: number }
  ) =>
    requests().post(
      `/locations/${locationId}/tasks/search`,
      JSON.stringify(body),
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    ),
};
