import { TransferRequestFetchParams } from '@/components/locationTransfer/Transfer.const';
import { requests } from '.';

export const InternalToolsService = {
  fetchLocationTransferRequests: (params: TransferRequestFetchParams) =>
    requests().get('/internal-tools/location-transfers', {
      params,
    }),
  fetchEjectRequests: (query: string) =>
    requests().get('/internal-tools/location-transfers/eject?' + query),
  replyToTransferRequest: (payload: any) =>
    requests().put('/internal-tools/location-transfers', payload),
  replyToEjectRequest: (id: string, payload: any) =>
    requests().put(`/internal-tools/location-transfers/eject/${id}`, payload),
  bulkLocationsCheck: (payload: any) =>
    requests().post(
      '/internal-tools/location-transfers/bulk-location-check',
      payload
    ),
  fetchBulkTransferRequests: (payload: any) =>
    requests().get('/internal-tools/location-transfers/bulk-transfer', {
      params: payload,
    }),
  fetchBulkTransferRequestsForCompany: (companyId: string, payload: any) =>
    requests().get(
      `/internal-tools/location-transfers/bulk-transfer/company/${companyId}`,
      { params: payload }
    ),
  fetchBulkTransferById: (
    bulkId: string,
    params: { companyId: string; search?: string }
  ) =>
    requests().get(
      `/internal-tools/location-transfers/bulk-transfer/${bulkId}`,
      { params }
    ),

  replyBulkTransferRequest: (bulkId, payload: any) =>
    requests().put(
      `/internal-tools/location-transfers/bulk-transfer/${bulkId}`,
      payload
    ),

  bulkTransfer: (payload: any) =>
    requests().post(
      `/internal-tools/location-transfers/bulk-transfer`,
      payload
    ),

  exportBulkData: (companyId: string, bulkId: string) =>
    requests().get(
      `/internal-tools/location-transfers/${companyId}/export-bulk/${bulkId}`
    ),
};
