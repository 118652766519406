<template>
  <UIContentWrap>
    <UIModal
      :show="showModal"
      :width="modalWidth"
      :mask-closable="false"
      @update:show="$emit('update:showModal', $event)"
    >
      <template #header>
        <UIModalHeader
          id="modal-header"
          :type="modalType === 'success' ? 'success' : 'error'"
          :icon="modalType === 'success' ? CheckCircleIcon : SlashCircle01Icon"
          @close="$emit('update:showModal', false)"
        />
      </template>
      <UIModalContent v-if="modalType === 'success'" class="!-pt-5">
        <div class="!pb-3 text-lg font-semibold text-gray-900">
          {{ $t('bulkRequestSubmitted') }}
        </div>

        <i18n-t
          keypath="bulkRequestSubmittedDescription"
          tag="div"
          class="text-gray-600"
        >
          <template #pagename>
            <span
              class="cursor-pointer text-primary-500"
              @click="successAndErrorClick"
              >{{ t('sent') }}</span
            >
          </template>
        </i18n-t>
      </UIModalContent>
      <UIModalContent v-else class="!-pt-5">
        <div class="!pb-3 text-lg font-semibold text-error-600">
          {{ $t('bulkTransferRequestFailed') }}
        </div>
        <div class="!pb-3">{{ errorMessage }}</div>
        <div v-if="locationIds && locationIds.length > 0">
          {{ $t('errorLocations') }} - {{ locationIds.join(', ') }}
        </div>
      </UIModalContent>
      <template #footer>
        <UIModalFooter
          v-if="modalType === 'success'"
          id="bulkOptionSelectModal-process"
          type="primary"
          :positive-text="$t('viewDetails')"
          :negative-text="$t('close')"
          @positive-click="proceed"
          @negative-click="$emit('update:showModal', false)"
        ></UIModalFooter>
        <UIModalFooter
          v-else
          id="bulkOptionSelectModal-process-cancel"
          type="primary"
          :negative-text="$t('goBack')"
          @negative-click="$emit('update:showModal', false)"
        ></UIModalFooter>
      </template>
    </UIModal>
  </UIContentWrap>
</template>

<script setup>
import { router } from '@/router';
import { useAppStore } from '@/store/app';
import {
  CheckCircleIcon,
  SlashCircle01Icon,
} from '@gohighlevel/ghl-icons/24/outline';
import {
  UIContentWrap,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
} from '@gohighlevel/ghl-ui';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  modalWidth: {
    type: Number,
    default: 450,
  },
  modalType: {
    type: String,
    default: 'success',
  },
  errorMessage: {
    type: String,
    default: 'Something went wrong. Please try again.',
  },
  locationIds: {
    type: Array[String],
    default: [],
  },
});
const emit = defineEmits(['update:showModal', 'success']);

function proceed() {
  const appStore = useAppStore();
  const path = `/sub-accounts/transfer-locations?&tab=sent&type=bulk`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then(parent => {
      parent.emit('route-change', {
        path,
      });
    });
  }
  router.push(path);
  emit('success');
}
</script>

<style scoped></style>
