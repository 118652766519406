<template>
  <UIContentWrap>
    <UIModal
      :show="showModal"
      :width="successAndError.show ? 400 : modalWidth"
      :mask-closable="false"
      @update:show="closeModal"
    >
      <template #header>
        <UIModalHeader
          id="modal-header"
          :type="
            successAndError.show
              ? successAndError.type === 'success'
                ? 'success'
                : 'error'
              : 'primary'
          "
          :title="
            successAndError.show
              ? successAndError.title
              : step === 1
              ? $t('bulkTransferAccounts', { count: locationIds.length })
              : $t('confirmBulkRequest')
          "
          :description="t('confirmDetails')"
          :icon="Repeat02Icon"
          @close="$emit('update:showModal', false)"
        />
      </template>
      <hr class="!-mx-6 h-[1px] border-0 bg-gray-200" />
      <UIModalContent v-if="successAndError.show">
        <div v-if="successAndError.type === 'success'">
          <i18n-t
            keypath="bulkRequestSubmittedDescription"
            tag="div"
            class="text-gray-600"
          >
            <template #pagename>
              <span
                class="cursor-pointer text-primary-500"
                @click="successAndErrorClick"
                >{{ t('sent') }}</span
              >
            </template>
          </i18n-t>
        </div>
        <div v-else>
          {{ successAndError.description }}
        </div>
      </UIModalContent>
      <UIModalContent v-else-if="step === 1">
        <div
          class="rounded-lg !border border-solid !border-gray-300 bg-gray-25 !px-3 !py-3"
        >
          <div
            class="flex h-8 w-8 items-center justify-center rounded-full bg-warning-100 text-center"
          >
            <AlertTriangleIcon class="h-5 w-5 text-warning-600" />
          </div>

          <i18n-t
            keypath="consent_sub_account_transfer"
            tag="div"
            class="pt-3 text-sm font-semibold text-gray-700"
          >
            <template #link>
              <a
                href="https://help.gohighlevel.com/en/support/solutions/articles/155000002031-sub-account-transfers-all-you-need-to-know"
                target="_blank"
                class="!text-[#155EEF] !underline"
                >{{ $t('sub_account_transfer_guidelines') }}</a
              >
            </template>
          </i18n-t>

          <div class="mb-4 mt-3">
            <UICheckbox
              id="bulk-confirm-checkbox"
              v-model:checked="consent"
              type="default"
              size="large"
            >
              <div class="text-sm font-semibold text-gray-700">
                {{ $t('transferConsent') }}
              </div></UICheckbox
            >
          </div>
          <hr class="h-[1px] border-0 bg-gray-200" />
          <div class="flex space-x-2">
            <AlertCircleIcon class="h-4 w-4" />
            <div class="font-regular text-sm text-gray-600">
              {{ $t('transferInfo') }}
            </div>
          </div>
        </div>
        <hr class="!mb-8 !mt-5 h-[1px] border-0 bg-gray-200" />
        <UIForm
          id="consentForm"
          ref="form"
          :model="formValue"
          :rules="formRules"
        >
          <UIFormItem :label="$t('receivingRelNo')" path="relNo">
            <UIInput
              id="relationshipNumber"
              v-model="formValue.relNo"
              type="text"
              :placeholder="$t('enterRelNo')"
            ></UIInput>
          </UIFormItem>
        </UIForm>
      </UIModalContent>
      <UIModalContent v-else>
        <div class="font-regular !mb-5 text-sm text-gray-700">
          {{ $t('transferConfirmation', { relNo: formValue.relNo }) }}
        </div>

        <div class="!mb-2 text-sm font-medium text-gray-700">
          {{ $t('confirmPassword') }}
        </div>
        <UIInput
          id="password"
          v-model="password"
          type="password"
          :placeholder="$t('enterPassword')"
          class="!mb-4"
        ></UIInput>
        <div v-if="passwordError" class="text-error-600">
          {{ $t('invalidPassword') }}
        </div>
      </UIModalContent>
      <template #footer>
        <UIModalFooter
          v-if="!successAndError.show"
          id="bulkOptionSelectModal-process"
          type="error"
          :positive-text="
            step === 1 ? $t('requestTransfer') : $t('confirmTransfer')
          "
          :loading="isLoading"
          :negative-text="step === 1 ? $t('notNow') : $t('back')"
          :disabled="step === 1 ? !consent || !formValue.relNo : !password"
          @positive-click="proceed"
          @negative-click="negativeClick"
        ></UIModalFooter>
        <UIModalFooter
          v-if="successAndError.show"
          id="bulkOptionSelectModal-success"
          :type="successAndError.type === 'success' ? 'primary' : 'error'"
          :positive-text="successAndError.buttonText"
          :loading="isLoading"
          @positive-click="successAndErrorClick"
        ></UIModalFooter>
      </template>
    </UIModal>
  </UIContentWrap>
</template>

<script setup lang="ts">
import { UserService } from '@/services';
import { InternalToolsService } from '@/services/InternalToolsService';
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  Repeat02Icon,
} from '@gohighlevel/ghl-icons/24/outline';
import {
  UICheckbox,
  UIContentWrap,
  UIForm,
  UIFormItem,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
} from '@gohighlevel/ghl-ui';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },

  userId: {
    type: String,
    required: true,
  },
  companyId: {
    type: String,
    required: true,
  },
  locationIds: {
    type: Array,
    required: true,
  },
});

const form = ref();
const modalWidth = ref(600)
const emit = defineEmits(['update:showModal', 'success', 'close']);
const consent = ref(false);
const step = ref(1);
const password = ref('');
const isLoading = ref(false);
const passwordError = ref(false);
const formValue = ref({
  relNo: '',
});

const defaultSuccesssAndError = {
  show: false,
  type: 'success',
  title: '',
  description: '',
  buttonType: 'primary',
  buttonText: '',
}

const successAndError = ref(defaultSuccesssAndError)

function resetFormValues() {
  formValue.value = {
    relNo: '',
  };
}

function isValidRelationshipNumber(value: string) {
  return /^[0-9]{1}[-][0-9]{3}[-][0-9]{3}$/.test(value);
}

const formRules = {
  relNo: {
    required: true,
    message: t('valid_rel'),
    trigger: ['input', 'blur'],
    validator(_, value) {
      const valid = isValidRelationshipNumber(value);
      if (!valid) {
        return new Error(t('invalidRelNo'));
      }
      return valid;
    },
  },
};

async function proceed() {
  try {
    if (step.value === 1) {
      await form.value.getForm().validate();
      step.value = 2;
    } else {
      await checkPassword();
    }
  } catch (validationErr) {}
}

function closeModal() {
  step.value = 1;
  password.value = '';
  resetFormValues();
  emit('close');
}

function negativeClick() {
  if (step.value === 1) {
    closeModal();
  } else {
    password.value = ''
    step.value = 1;
  }
}

async function processBulkTransfer() {
  try {
    isLoading.value = true
    await InternalToolsService.bulkTransfer({
      locationIds: props.locationIds,
      releasingCompanyId: props.companyId,
      receivingCompanyRelNo: formValue.value.relNo,
      inApp: true,
    })

    successAndError.value = {
      show: true,
      type: 'success',
      title: t('bulkRequestSubmitted'),
      description: t('bulkRequestSubmittedDescription'),
      buttonType: 'primary',
      buttonText: 'Close',
    }
  } catch (error) {
    // emit('close')
    successAndError.value = {
      show: true,
      type: 'error',
      title: t('error'),
      description:
        error?.response?.data?.message ||
        error?.message ||
        t('something_went_wrong'),
      buttonType: 'primary',
      buttonText: 'Back',
    }
  } finally {
    isLoading.value = false
  }
}

async function successAndErrorClick() {
  if (successAndError.value.type === 'success') {
    closeModal()
    emit('success')
  } else {
    successAndError.value = defaultSuccesssAndError
    password.value = ''
    step.value = 1;
  }
}

async function checkPassword() {
  try {
    isLoading.value = true
    passwordError.value = false
    const { data: passwordCheck } = await UserService.validatePassword({
      userId: props.userId,
      password: password.value,
    })
    if (passwordCheck?.valid) {
      await processBulkTransfer()
    } else {
      passwordError.value = true
    }
  } catch (error) {
    passwordError.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped></style>
