<template>
  <div id="location-transfer" class="container-height pl-8 pr-8">
    <div v-show="!showBulkView.show">
      <div class="mt-11 px-3">
        <div
          id="location-transfer__btn-back-to-account"
          type="default"
          class="font-bold"
          @click="backToAccount"
        >
          <UITextSmMedium
            class="inline-flex cursor-pointer content-center items-center font-bold text-blue-500"
          >
            <ArrowLeftIcon class="mr-3 h-5 w-5" />
            {{ $t('backToSubAccounts') }}
          </UITextSmMedium>
        </div>

        <div class="flex">
          <div class="mb-4 mt-6 justify-between">
            <UIDisplaySmMedium>{{
              $t('subAccountTransfer')
            }}</UIDisplaySmMedium>
            <UITextSmMedium class="text-gray-500">{{
              $t('manageSubAccTransfer')
            }}</UITextSmMedium>
          </div>
          <div class="!ml-auto mt-6">
            <UIButton
              id="location-transfer__btn-bulk-transfer"
              type="primary"
              class="font-bold"
              @click="bulkTransfer"
            >
              <UITextSmMedium
                class="inline-flex cursor-pointer content-center items-center"
              >
                <Repeat02Icon class="mr-3 h-5 w-5" />
                {{ $t('bulkLocationTransfer') }}
              </UITextSmMedium>
            </UIButton>
          </div>
        </div>
      </div>
      <!-- <hr /> -->
      <div class="m-2">
        <UITabs
          :type="'line'"
          :animated="true"
          :value="undefined"
          :default-value="defaultTab"
          :justify-content="'start'"
        >
          <UITabPane name="received" :tab="$t('received')"
            ><TransferTable
              :key="renderKey"
              :tab-name="'received'"
              :bulk-option="true"
              :bulk="bulk"
              @update-tab="updateTab"
              @view-details="viewDetails"
          /></UITabPane>
          <UITabPane name="sent" :tab="$t('sent')"
            ><TransferTable
              :key="renderKey"
              :tab-name="'sent'"
              :bulk-option="true"
              :bulk="bulk"
              @update-tab="updateTab"
              @view-details="viewDetails"
          /></UITabPane>
          <UITabPane name="eject" :tab="$t('eject')"
            ><TransferTable :tab-name="'eject'"
          /></UITabPane>
        </UITabs>
      </div>
      <SelectBulkOption v-model:show-modal="showModal" @success="refresh" />
    </div>
    <div v-if="showBulkView.show">
      <BulkListView
        :bulk-details="showBulkView.bulkDetails"
        :status="showBulkView.status"
        :bulk-type="showBulkView.type"
        :tab="showBulkView.tab"
        @back="backToTable"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { router } from '@/router';
import { useAppStore } from '@/store/app';
import { ArrowLeftIcon, Repeat02Icon } from '@gohighlevel/ghl-icons/24/outline';
import {
  UIButton,
  UIDisplaySmMedium,
  UITabPane,
  UITabs,
  UITextSmMedium,
} from '@gohighlevel/ghl-ui';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import BulkListView from './BulkListView.vue';
import SelectBulkOption from './SelectBulkOption.vue';
import TransferTable from './TransferTable.vue';
const { t } = useI18n();
const appStore = useAppStore();
const bulk = ref<boolean>(false)
const defaultTab = ref<string>('received')
const showModal = ref<boolean>(false)
const renderKey = ref<number>(0)
const showBulkView = ref<any>({ show: false })
onBeforeMount(() => {
  const query = router.currentRoute.value.query;
  if (
    query?.tab &&
    ['received', 'sent', 'eject'].includes(query.tab as string)
  ) {
    defaultTab.value = query.tab as string;
  }
  if (query?.bulk && query.bulk === 'true') {
    bulk.value = true
  }
});

function backToAccount() {
  const path = `/sub-accounts`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then(parent => {
      parent.emit('route-change', {
        path,
      });
    });
  }
  router.push(path);
}

function bulkTransfer() {
  showModal.value = true
}

function refresh() {
  showModal.value = false
}

function updateTab(tabName: string) {
  bulk.value = tabName === 'bulk' ? true : false
  renderKey.value += 1
}

function viewDetails(data: any) {
  showBulkView.value = { ...data, companyId: appStore?.companyId };
}

function backToTable() {
  showBulkView.value = { show: false };
  renderKey.value += 1
}
</script>

<style scoped>
.container-height {
  min-height: calc(100vh - 90px);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e7eb;
  margin: 1em 0;
  padding: 0;
}
</style>
