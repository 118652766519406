export enum TransferStatus {
  PENDING = 'pending',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  FAILED = 'failed',
}

export enum BulkTransferStatus {
  PENDING = 'pending',
  CANCELED = 'canceled',
  PROCESSED = 'processed',
  REJECTED = 'rejected',
}

export enum EjectStatus {
  PENDING = 'pending',
  PAYMENT_PENDING = 'payment_pending',
  COMPLETED = 'completed',
  CANCELED = 'cancelled',
}

export enum TransferReply {
  ACCEPT = 'accept',
  REJECT = 'reject',
  CANCEL = 'cancel',
}

export type TransferRequestFetchParams = {
  companyId?: string
  type?: string
  bulk?: boolean
  skip?: number
  limit?: number
  subAccountName?: string
}

export type LocationTransferTableProps = {
  tabName: string //'received' | 'sent' | 'eject'
  bulk: boolean
}
