<template>
  <UIContentWrap>
    <UIModal
      :show="showModal"
      :width="modalWidth"
      :mask-closable="false"
      @update:show="$emit('update:showModal', $event)"
    >
      <UIModalContent>
        <LocationTransferImage class="mx-auto flex" />
        <div class="!mt-4 text-center text-lg font-semibold text-gray-900">
          {{ $t('selectSubAccounts') }}
        </div>
        <UITextSmRegular class="!mb-4 text-center text-gray-600">
          {{ $t('subAccountTransferWarning') }}</UITextSmRegular
        >
      </UIModalContent>
      <template #footer>
        <UIModalFooter
          id="bulkOptionSelectModal-process"
          type="primary"
          :positive-text="$t('proceed')"
          :negative-text="$t('cancel')"
          @positive-click="proceed"
          @negative-click="$emit('update:showModal', false)"
        ></UIModalFooter>
      </template>
    </UIModal>
  </UIContentWrap>
</template>

<script setup lang="ts">
import LocationTransferImage from '@/assets/location-transfer.svg';
import { router } from '@/router';
import { useAppStore } from '@/store/app';
import {
  UIContentWrap,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UITextSmRegular,
} from '@gohighlevel/ghl-ui';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  modalWidth: {
    type: Number,
    default: 450,
  },
})
const emit = defineEmits(['update:showModal', 'success'])

function proceed() {
  const appStore = useAppStore();
  const path = `/sub-accounts/transfer-locations/initiate-bulk-transfer`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then(parent => {
      parent.emit('route-change', {
        path,
      });
    });
  }
  router.push(path);
}
</script>

<style scoped></style>
