<template>
  <div id="bulk-location-transfer" class="container-height !pl-8 !pr-8">
    <div class="!mt-11 !px-3">
      <div
        id="location-transfer__btn-back-to-account"
        type="default"
        class="font-bold"
        @click="backToLocationTransfer"
      >
        <UITextSmMedium
          class="inline-flex cursor-pointer content-center items-center font-bold text-blue-500"
        >
          <ArrowLeftIcon class="mr-3 h-5 w-5" />
          {{ $t('backToLocationTransfer') }}
        </UITextSmMedium>
      </div>

      <div class="flex">
        <div class="mb-4 mt-4 justify-between">
          <UIDisplaySmMedium>{{
            $t('initiateBulkTransfer')
          }}</UIDisplaySmMedium>
          <UITextSmRegular class="text-gray-500">{{
            $t('initiateBulkTransferDescription')
          }}</UITextSmRegular>
        </div>
      </div>
    </div>
    <div class="!px-3">
      <UIRadio
        id="radiobtn-location-transfer"
        :name="'radio-btn'"
        :disabled="false"
        :checked="checkedValue === 'list'"
        value="list"
        :size="'large'"
        @change="handleChange('list')"
      >
        {{ $t('selectFromList') }}
      </UIRadio>
      <UIRadio
        :name="'radio-btn'"
        :disabled="false"
        :checked="checkedValue === 'manual'"
        value="manual"
        :size="'large'"
        @change="handleChange('manual')"
      >
        {{ $t('addManually') }}
      </UIRadio>
    </div>

    <div v-if="checkedValue === 'list'" class="!mt-5 !px-3">
      <UITable
        id="contacts-restore-table"
        v-model:search-text="table.searchText"
        :columns="table.columns"
        :row-key="rowKey"
        :data="table.tableData"
        :loading="table.loading"
        :hide-border="false"
        :hide-cell-border="false"
        :hide-heading-border="false"
        :checked-row-keys="table.selectedIds"
        :enable-text-search="true"
        search-input-placeholder="Search Sub-Account"
        :page-count="table.totalPages"
        :page="table.currentPage"
        @update:search-text="searchInputChange"
        @update:checked-row-keys="handleCheck"
        @update:page="pageChange"
      >
        <template #filter-left>
          <div class="flex space-x-2 !pl-2">
            <div>
              {{ $t('accountsSelected', { count: table.selectedIdsCount }) }}
            </div>
            <div
              v-if="table.selectedIdsCount < 1000"
              class="cursor-pointer text-primary-600"
              @click="selectAll"
            >
              {{
                $t('selectAllSubAccounts')
              }} {{table.totalLocationsCount > 1000? t('max1000'): ''}}
            </div>
            <div
              v-if="table.selectedIdsCount > 0"
              class="cursor-pointer text-error-600"
              @click="deselectAll"
            >
              {{ $t('clearAll') }}
            </div>
          </div>
        </template>
      </UITable>
    </div>

    <div v-if="checkedValue === 'manual'" class="!mt-5 !px-3">
      <div class="!mb-2">
        <UIAlert :closable="false" type="default">
          <template #title>{{ $t('addManualLocations') }}</template>
          <template #content>{{ $t('manualAllLocationIds') }}</template>
        </UIAlert>
      </div>
      <div class="!mb-2 text-sm font-medium">{{ $t('enterLocationIds') }}</div>
      <UIInput
        id="location-transfer__input-manual"
        v-model="manualInput"
        placeholder="Add Location ID's you want to transfer..."
        rows="8"
        type="textarea"
        @input="checkManualDisabled"
        @keydown.enter.exact.prevent
      ></UIInput>
    </div>

    <div class="!mt-4 flex space-x-2 !px-3">
      <UIButton
        id="location-transfer__btn-Cancel"
        type="default"
        class="!ml-auto"
        @click="backToLocationTransfer"
        >{{ $t('cancel') }}</UIButton
      >
      <UIButton
        id="location-transfer__btn-next"
        type="primary"
        :disabled="nextDisabled"
        :loading="isNextLoading"
        @click="checkLocationsForTransfer"
        >{{ $t('next') }}</UIButton
      >
    </div>
  </div>
  <TransferConfirmationModal
    v-model:show-modal="showModal"
    :user-id="userId"
    :location-ids="idsToTransfer"
    :company-id="appStore.companyId"
    @close="closeTransferConfirmationModal"
    @success="createBulkTransferSuccess"
  />

  <SuccessAndErrorModal
    v-model:show-modal="showSuccessErrorModal"
    :modal-type="modalType"
    :error-message="errorMessage"
    :location-ids="errorLocationIds"
    @close="closeSuccessErrorModal"
  />
</template>

<script setup>
import { router } from '@/router';
import { InternalToolsService } from '@/services/InternalToolsService';
import { LocationService } from '@/services/LocationService';
import { useAppStore } from '@/store/app';
import { ArrowLeftIcon } from '@gohighlevel/ghl-icons/24/outline';
import {
  UIAlert,
  UIButton,
  UIDisplaySmMedium,
  UIInput,
  UIRadio,
  UITable,
  UITextSmMedium,
  UITextSmRegular,
} from '@gohighlevel/ghl-ui';
import { debounce } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SuccessAndErrorModal from './SuccessAndErrorModal.vue';
import TransferConfirmationModal from './TransferConfirmationModal.vue';
const { t } = useI18n();
const appStore = useAppStore();
const manualInput = ref('');
const checkedValue = ref('list');
const nextDisabled = ref(true);
const isNextLoading = ref(false);
const showModal = ref(false);
const showSuccessErrorModal = ref(false);
const modalType = ref('success');
const errorMessage = ref('');
const errorLocationIds = ref([]);
const idsToTransfer = ref([]);
const table = ref({
  loading: false,
  columns: [
    {
      type: 'selection',
    },
    {
      title: t('subAccountName'),
      key: 'subAccountDetails',
      render(row) {
        return row.name;
      },
    },
    {
      title: t('subAccountId'),
      key: 'subAccountId',
      render(row) {
        return row._id;
      },
    },
  ],
  selectedIds: [],
  tableData: [],
  totalPages: 0,
  currentPage: 1,
  pageLength: 10,
  searchText: '',
  totalLocationsCount: 0,
  selectAll: false,
  selectedIdsCount: 0,
  deselectIds: [],
});

onMounted(() => {
  fetchLocation();
});

const userId = computed(() => {
  return appStore.user.id;
})

function backToLocationTransfer(query) {
  const path = `/sub-accounts/transfer-locations${query ? '?' + query : ''}`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then(parent => {
      parent.emit('route-change', {
        path,
      });
    });
  }
  router.push(path);
}

function findDifference(arr1, arr2) {
  return arr1.filter(x => !arr2.includes(x));
}

async function getLocations(findAll = false) {
  try {
    const skip = (table.value.currentPage - 1) * table.value.pageLength;
    const locations = await LocationService.getAll(
      appStore?.companyId,
      table.value.searchText,
      findAll ? 1000 : table.value.pageLength, //max 1000 locations
      skip > 0 ? skip : 0,
      'desc',
      '_id,name'
    );
    return locations
  } catch (error) {}
}

async function fetchLocation() {
  try {
    if (!appStore?.companyId) {
      return setTimeout(() => {
        fetchLocation();
      }, 5000);
    }
    table.value.loading = true;
    const response = await getLocations();

    if (response.data.locations.length === 0) {
      table.value.totalPages = table.value.currentPage - 1;
      table.value.currentPage = table.value.currentPage - 1;
      table.value.totalLocationsCount =
        (table.value.currentPage - 1) * table.value.pageLength +
        table.value.tableData.length;
    } else {
      table.value.tableData = response.data.locations;
      table.value.totalLocationsCount = response.data.hit[0].count;
      table.value.totalPages = Math.ceil(
        response.data.hit[0].count / table.value.pageLength
      );
    }

  } catch (error) {
  } finally {
    table.value.loading = false;
  }
}

function checkListDisabled() {
  if (table.value.selectedIdsCount === 0) {
    nextDisabled.value = true;
  } else {
    nextDisabled.value = false;
  }
}

function checkManualDisabled() {
  if (manualInput.value === '') {
    nextDisabled.value = true;
  } else {
    nextDisabled.value = false;
  }
}

function handleChange(e) {
  checkedValue.value = e;
  if (checkedValue.value === 'manual') {
    checkListDisabled()
  } else if (manualInput.value === '') {
    nextDisabled.value = true;
  }
}

const rowKey = row => {
  return row._id;
};

const handleCheck = selectedRowKeys => {
  if(selectedRowKeys.length > 1000){
    window.alert(t('selectAllError'))

  } else {
    table.value.selectedIds = selectedRowKeys;
    table.value.selectedIdsCount = selectedRowKeys.length;
  // }
  checkListDisabled()}
};

const selectAll = async() => {
  try{
    table.value.loading = true;
    table.value.selectAll = true;
    const allLocations = await getLocations(true)
    table.value.selectedIds = allLocations.data.locations.map(row => row._id);
    table.value.deselectIds = [];
    table.value.selectedIdsCount = allLocations.data.locations.length;
    nextDisabled.value = false
  }catch(err){

  }finally{
    table.value.loading = false;
  }
};

const debounceFetch = debounce(() => {
  fetchLocation();
}, 500);

const searchInputChange = value => {
  table.value.searchText = value;
  debounceFetch();
};

const deselectAll = () => {
  table.value.selectAll = false;
  table.value.selectedIds = [];
  table.value.deselectIds = [];
  table.value.selectedIdsCount = 0;
  nextDisabled.value = true
};


const pageChange = page => {
  table.value.currentPage = page;
    fetchLocation();
};

const showConfirmationModal = async () => {
    idsToTransfer.value = table.value.selectedIds;
    showModal.value = true;
  // }
};

const checkLocationsForTransfer = async () => {
  try {
    isNextLoading.value = true;

    if (checkedValue.value === 'manual') {
      table.value.selectedIdsCount = manualInput.value.split(',').length;
    }
    let selectedIdsToTransfer = [];
    if (checkedValue.value === 'manual') {
      selectedIdsToTransfer = manualInput.value.split(',').map(e => e.trim())
    } else {
      selectedIdsToTransfer = table.value.selectedIds;
    }
    await InternalToolsService.bulkLocationsCheck({
      companyId: appStore?.companyId,
      bulkTransferType: checkedValue.value,
      allLocations:false,
      locationIds: selectedIdsToTransfer,
      deselectIds:
        checkedValue.value === 'manual' ? [] : table.value.deselectIds,
    });
    await showConfirmationModal();
  } catch (error) {
    modalType.value = 'error';
    if (error.response.data.message) {
      errorMessage.value = error.response.data.message;
    }
    if (error.response.data.locations) {
      errorLocationIds.value = error.response.data.locations;
    }
    showSuccessErrorModal.value = true;
  } finally {
    isNextLoading.value = false;
  }
};

function createBulkTransferSuccess() {
  backToLocationTransfer(`tab=sent&bulk=true`)
}

function closeSuccessErrorModal() {
  showSuccessErrorModal.value = false;
}
function closeTransferConfirmationModal() {
  showModal.value = false;
}
</script>

<style scoped>
.container-height {
  min-height: calc(100vh - 90px);
}
</style>
